<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
      <ActionButton
        class="mr-3"
        v-b-toggle.comments-collapse
        title="Ausklappen"
        :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
        @click="toggleCollapse"
        v-if="$route.name === 'reisetermine-view' || $route.name === 'reisetermin-view-releasepruefung'"
      />
      <h3 class="card-title font-weight-bolder">Kommentare</h3>
      <span><b-spinner v-if="isLoading" label="Spinning"></b-spinner></span>

      <div class="card-toolbar" v-if="showDepartmentButtons">
        <b-button
          v-for="(department, index) in departmentsOfCommentCreators"
          class="px-1 mx-1"
          :class="`border-${departmentButtonClass(department).border}`"
          :style="{
            'background-color':
              selectedDepartment === departmentButtonClass(department).label
                ? departmentButtonClass(department).color
                : '',
            'border-color': departmentButtonClass(department).color,
            color:
              selectedDepartment === departmentButtonClass(department).label
                ? departmentButtonClass(department).fontColor
                : '',
          }"
          :key="index"
          style="
            height: calc(1.5em + 1.3rem + 2px);
            width: calc(1.5em + 1.3rem + 2px);
            font-weight: bold;
            font-size: 0.8rem;
          "
          @click="changeDepartment(departmentButtonClass(department).label)"
        >
          {{ departmentButtonClass(department).label }}
        </b-button>
      </div>
    </div>

    <div class="card-body p-0">
      <div class="mb-2 overflow-auto">
        <perfect-scrollbar class="scroll">
          <div>
            <template v-if="filteredComments.length !== 0">
              <div v-if="paginatedComments.length === 0" class="shadow-xs"></div>
              <TransitionGroup name="commentslist" tag="div">
                <div
                  v-for="comment in paginatedComments"
                  :key="'comment-' + comment.id"
                  class="shadow-xs comment-box"
                  :class="{ 'bg-warning-o-50': comment.id === currentlyEditingCommentObject }"
                >
                  <ReiseterminComment
                    :comment="comment"
                    :isAdmin="isAdmin"
                    :isLoading="isLoading"
                    :ownUserId="ownUserId"
                    @comment-delete="onClickCommentDelete"
                    @comment-edit="onClickCommentEdit"
                    @comment-reply="onClickCommentReply"
                    @comment-mark="onClickCommentMark"
                  ></ReiseterminComment>
                </div>
              </TransitionGroup>
            </template>
            <template v-else>
              <div class="shadow-xs">
                <div class="d-flex align-items-start card-spacer-x py-4">
                  <div class="d-flex flex-column flex-grow-1 flex-wrap mr-2">
                    <div class="d-flex flex-column">
                      <div class="font-weight-bold text-muted">
                        {{ $t('COMMON.EMPTYTEXT', { name: 'Kommentare' }) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </perfect-scrollbar>
        <b-pagination
          v-if="filteredComments.length > perPage"
          class="shadow-xs p-2 w-100"
          align="fill"
          prev-text="<"
          next-text=">"
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="filteredComments.length"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
    <b-collapse id="comments-collapse" :visible="isInputFieldExpanded">
      <ReiseterminCommentAnswerBox
        ref="reiseterminCommentAnswerBox"
        :value.sync="commentText"
        :currentlyEditingCommentObject="currentlyEditingCommentObject"
        :currentlyEditingCommentType="currentlyEditingCommentType"
        :isLoading="isLoading"
        @comment-save="onClickSendComment"
        @comment-edit-cancel="onClickCancelComment"
        @comment-delete-my-comment="onClickDeleteMyComment"
      ></ReiseterminCommentAnswerBox>
    </b-collapse>
  </div>
</template>

<script>
import ReiseterminComment from '@/components/produkte/reisetermine/reisetermin-comment.vue';
import ReiseterminCommentAnswerBox from '@/components/produkte/reisetermine/reisetermin-comment-answer-box.vue';
import { parseISO, compareAsc } from 'date-fns';
import { mapGetters, mapState } from 'vuex';
import ActionButton from '@/components/common/action-button.vue';
import { departmentButtonClass } from '@/core/common/helpers/utils';

export default {
  components: { ReiseterminComment, ReiseterminCommentAnswerBox, ActionButton },
  props: {
    comments: { type: Array },
    currentlyEditingCommentObject: { type: Object, default: null },
    currentlyEditingCommentType: { type: String, default: null },
    isAdmin: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    ownUserId: { type: String },
    reiseterminObj: { type: Object },
    showDepartmentButtons: { type: Boolean, default: false },
    isInputFieldExpanded: { type: Boolean, default: true },
  },
  data() {
    return {
      commentText: '',
      perPage: 5,
      currentPage: 1,
      selectedDepartment: null,
      departmentsArray: [],
      isCollapseOpen: true,
    };
  },
  watch: {
    filteredComments: {
      immediate: true,
      handler: function (c) {
        this.currentPage = 1; // Changed because #1821
      },
    },
    reiseterminObj() {
      if (this.$route.fullPath.includes('releasepruefung')) {
        if (this.departmentsOfCommentCreators.includes('Destination Operations Management')) {
          this.selectedDepartment = 'DOM';
        } else {
          this.selectedDepartment = null;
        }
      }
    },
  },
  mounted() {
    if (this.$route.fullPath.includes('releasepruefung')) {
      if (this.departmentsOfCommentCreators.includes('Destination Operations Management')) {
        this.selectedDepartment = 'DOM';
      } else {
        this.selectedDepartment = null;
      }
    }
    if (this.isInputFieldExpanded === false) {
      this.isCollapseOpen = false;
    }
  },
  computed: {
    ...mapGetters([
      'getYieldTeamUsersIds',
      'getPmTeamUsersIds',
      'getDomTeamUsersIds',
      'getFomTeamUsersIds',
      'getCsmTeamUsersIds',
      'getTechTeamUsersIds',
      'getStammdatenTeamUsersIds',
    ]),
    ...mapState({
      users: state => state.users['users'],
    }),
    departmentsOfCommentCreators() {
      // überprüfung, ob das comments array leer ist
      if (this.comments.length === 0) {
        return [];
      } else {
        // es werden sich alle creator aus den kommentaren geholt -> geht über das gesamte array von Objects
        // ergebnis ist ein neues array mit den IDs
        const creatorIds = this.comments.map(comment => comment.creator);

        // geht über das users Array und überprüft, ob die ID in dem creators array enthalten ist
        const usersList = this.users.filter(user => creatorIds.includes(user.id));

        // geht über jedes usersList array und nimmt sich da dann das department heraus
        const departments = usersList.map(user => user.department);
        // es wird dann ein Set daraus gemacht, damit nur unique departments gespeichert werden
        return [...new Set(departments)];
      }
    },
    filteredComments() {
      if (this.selectedDepartment) {
        return this.comments.filter(comment => {
          const { creator } = comment;

          if (this.selectedDepartment === 'PM') {
            return this.getPmTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'Y') {
            return this.getYieldTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'DOM') {
            return this.getDomTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'FOM') {
            return this.getFomTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'CSM') {
            return this.getCsmTeamUsersIds.includes(creator);
          } else if (this.selectedDepartment === 'Tech') {
            return this.getTechTeamUsersIds.icludes(creator);
          } else if (this.selectedDepartment === 'STD') {
            return this.getStammdatenTeamUsersIds.includes(creator);
          }
          return false;
        });
      }
      return this.comments;
    },
    paginatedComments() {
      const sortedComments = [...this.filteredComments].sort((a, b) =>
        compareAsc(parseISO(b.createdDateUtc), parseISO(a.createdDateUtc))
      );

      return sortedComments.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
  },

  methods: {
    departmentButtonClass,
    scrollToAnswerBox() {
      this.$refs['reiseterminCommentAnswerBox'].$el.scrollIntoView({ block: 'end' });
    },
    onClickCommentMark(commentObject) {
      this.$emit('comment-mark', commentObject);
    },
    onClickCommentReply(commentObject) {
      this.$emit('comment-reply', commentObject);
      this.commentText = '';
      this.scrollToAnswerBox();
    },
    onClickCancelComment() {
      this.$emit('comment-edit-cancel');
      this.commentText = '';
    },
    onClickSendComment() {
      this.$emit('comment-save', this.commentText);
      this.commentText = '';
    },
    onClickDeleteMyComment() {
      this.commentText = '';
    },
    onClickCommentDelete(commentObject) {
      this.$emit('comment-edit-cancel');
      this.$emit('comment-delete', commentObject);
    },
    onClickCommentEdit(commentObject) {
      this.$emit('comment-edit', commentObject);
      this.commentText = commentObject.text;
      this.scrollToAnswerBox();
    },

    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
    changeDepartment(departmentLabel) {
      this.selectedDepartment = this.selectedDepartment === departmentLabel ? null : departmentLabel;
    },
  },
};
</script>
<style lang="scss" scoped>
.commentslist-move, /* apply transition to moving elements */
.commentslist-enter-active,
.commentslist-leave-active {
  transition: all 0.3s ease;
}

.commentslist-enter-from,
.commentslist-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.commentslist-leave-active {
  position: absolute;
}

.comment-box {
  min-height: 100px;
}
:deep(.comment-box p) {
  word-break: break-word;
}
</style>
