<template>
  <ReiseterminViewCardComment
    :comments="comments"
    :currentlyEditingCommentObject="currentlyEditingCommentObject"
    :currentlyEditingCommentType="currentlyEditingCommentType"
    :ownUserId="ownUserId"
    :isAdmin="isAdmin"
    :isLoading="isLoading"
    :reiseterminObj="reiseterminObj"
    :showDepartmentButtons="showDepartmentButtons"
    :isInputFieldExpanded="isInputFieldExpanded"
    @comment-reply="onCommentReply"
    @comment-save="onCommentSave"
    @comment-delete="onCommentDelete"
    @comment-edit="onCommentEdit"
    @comment-edit-cancel="onCommentEditCancel"
    @comment-mark="onCommentMark"
  ></ReiseterminViewCardComment>
</template>

<script>
import ReiseterminViewCardComment from '@/components/produkte/reisetermine/reisetermin-card-comment.vue';
import { mapActions, mapState } from 'vuex';
import EventBus from '@/core/common/plugins/event-bus.js';

import {
  SAVE_COMMENT,
  UPDATE_OR_SAVE_COMMENT,
  REQUEST_DELETE_COMMENT,
  START_EDITING_COMMENT,
  GET_COMMENTS,
  STOP_EDITING_COMMENT,
  MARK_COMMENT,
  UNMARK_COMMENT,
} from '@/core/produkte/reisetermine/stores/reisetermin-comments.module.js';
export default {
  components: { ReiseterminViewCardComment },
  props: {
    reisekuerzel: { type: String },
    reiseterminId: { type: Number },
    startdatum: { type: String },
    doNotDestroyReload: { type: Boolean, default: false },
    reiseterminObj: { type: Object },
    showDepartmentButtons: { type: Boolean, default: false },
    isInputFieldExpanded: { type: Boolean, default: true },
  },
  mounted() {
    this.getComments({
      id: this.reiseterminId,
      reisekuerzel: this.reisekuerzel,
      startdatum: this.startdatum,
    });
    EventBus.$on('reload', () => {
      this.getComments({
        id: this.reiseterminId,
        reisekuerzel: this.reisekuerzel,
        startdatum: this.startdatum,
      });
    });
  },
  beforeDestroy() {
    if (!this.doNotDestroyReload) {
      EventBus.$off('reload');
    }
  },
  watch: {
    commentEventMessage() {
      this.$bvToast.toast(this.commentEventMessage.text, this.commentEventMessage.options);
    },
  },
  computed: {
    ...mapState({
      ownUserId: state => state.auth.account.localAccountId,
      isAdmin: state => state.auth.account.idTokenClaims.roles.includes('Admin'),
      isLoading: state => state.reiseterminComments.isLoading,
      commentMaxDisplayLength: state => state.reiseterminComments.commentMaxDisplayLength,
      comments: state => state.reiseterminComments.comments,
      currentlyEditingCommentObject: state => state.reiseterminComments.currentlyEditingCommentObject,
      currentlyEditingCommentType: state => state.reiseterminComments.currentlyEditingCommentType,
      currentCommentText: state => state.reiseterminComments.currentCommentText,
      reisetermin: state => state.reiseterminComments.reisetermin,
      commentEventMessage: state => state.reiseterminComments.commentEventMessage,
    }),
  },
  methods: {
    ...mapActions({
      saveComment: SAVE_COMMENT,
      updateOrSaveComment: UPDATE_OR_SAVE_COMMENT,
      requestDeleteComment: REQUEST_DELETE_COMMENT,
      startEditingComment: START_EDITING_COMMENT,
      stopEditingComment: STOP_EDITING_COMMENT,
      markComment: MARK_COMMENT,
      unmarkComment: UNMARK_COMMENT,
      getComments: GET_COMMENTS,
    }),
    onCommentMark(commentObject) {
      if (!this.isLoading) {
        if (commentObject.isMarked) {
          this.unmarkComment({
            id: commentObject.id,
            reisekuerzel: this.reisekuerzel,
            startdatum: this.startdatum,
          });
        } else {
          this.markComment({
            id: commentObject.id,
            reisekuerzel: this.reisekuerzel,
            startdatum: this.startdatum,
          });
        }
      }
    },
    onCommentReply(commentObject) {
      if (!this.isLoading) {
        this.startEditingComment({ commentObject: commentObject, commentType: 'reply' });
      }
    },
    onCommentEditCancel() {
      if (!this.isLoading) {
        this.stopEditingComment();
      }
    },
    onCommentEdit(commentObject) {
      if (!this.isLoading) {
        this.startEditingComment({ commentObject: commentObject, commentType: 'edit' });
      }
    },
    onCommentSave(text) {
      if (!this.isLoading) {
        this.updateOrSaveComment({
          id: this.reisetermin.id,
          reisekuerzel: this.reisetermin.reisekuerzel,
          startdatum: this.reisetermin.startdatum,
          text: text,
        });
      }
    },
    onCommentDelete(commentObject) {
      if (!this.isLoading) {
        this.requestDeleteComment({
          commentObject: commentObject,
          reisekuerzel: this.reisekuerzel,
          startdatum: this.startdatum,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.comment-box {
  min-height: 85px;
}
:deep(.comment-box p) {
  word-break: break-word;
}
</style>
