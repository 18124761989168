<template>
  <div class="card-spacer-x gutter-b" style="width: 100%">
    <div class="card card-custom shadow-sm">
      <div class="card-body p-2">
        <form>
          <div class="d-block">
            <b-badge v-if="currentlyEditingCommentType === 'edit'" variant="warning" class="mb-1 w-100">
              <b>Bearbeiten</b>
              des Kommentars von {{ commentEditingStatusText }}
            </b-badge>
            <b-badge v-if="currentlyEditingCommentType === 'reply'" variant="primary" class="mb-1 w-100">
              <b>Antworten</b>
              auf {{ commentEditingStatusText }}
            </b-badge>
            <Editor2
              v-model="commentText"
              @input="$emit('update:value', $event)"
              :placeholder="'Schreibe einen Kommentar...'"
            />
          </div>
          <div class="d-flex align-items-center justify-content-between py-2">
            <div class="d-flex align-items-center mr-3">
              <div class="btn-group mr-4">
                <b-button
                  @click="$emit('comment-save', commentText)"
                  :disabled="isLoading"
                  variant="primary"
                  class="btn btn-primary font-weight-bold px-6"
                  :title="editingButtonText"
                >
                  {{ editingButtonText }}
                </b-button>
                <b-button
                  v-if="currentlyEditingCommentObject"
                  @click="$emit('comment-edit-cancel')"
                  :disabled="isLoading"
                  variant="danger"
                  :title="$t('COMMON.ABBRECHEN')"
                  class="btn btn-primary font-weight-bold px-6"
                >
                  Abbrechen
                </b-button>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span
                class="btn btn-icon btn-sm btn-clean"
                :class="{ disabled: isLoading }"
                data-inbox="dismiss"
                :title="$t('COMMON.KOMMENTARLOESCHEN')"
              >
                <i
                  @click="$emit('comment-delete-my-comment')"
                  class="flaticon2-rubbish-bin-delete-button"
                ></i>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Editor2 from '@/components/common/editor2.vue';

export default {
  name: 'ReiseterminCommentAnswerBox',
  components: { Editor2 },
  props: {
    value: { type: String },
    currentlyEditingCommentObject: { type: Object },
    currentlyEditingCommentType: { type: String },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    editingButtonText() {
      return this.currentlyEditingCommentType === 'reply'
        ? 'Antworten'
        : this.currentlyEditingCommentType === 'edit'
        ? 'Ändern'
        : 'Speichern';
    },
    commentEditingStatusText() {
      const creator = this.$store.getters.getUsernameById(this.currentlyEditingCommentObject.creator);
      const time = this.$options.filters.dateTime(this.currentlyEditingCommentObject.createdDateUtc);
      return `${creator} ${time}`;
    },
    commentText: {
      get() {
        return this.value;
      },
      set(value) {
        return value;
      },
    },
  },
};
</script>
