<template>
  <b-modal
    ref="reisetermin-anlegen-modal"
    id="modalReiseterminAnlegen"
    title="Reisetermin anlegen"
    class="modalReiseterminAnlegen"
    @show="onShow"
    @hide="onHide"
  >
    <b-overlay :show="isLoading">
      <b-form-group class="w-100" label="Reisekürzel" label-for="reisekuerzel">
        <Multiselect
          v-model="$v.form.reisekuerzel.$model"
          :options="reisenOptions"
          selectLabel=""
          selectGroupLabel=""
          deselectLabel=""
          deselectGroupLabel=""
          placeholder="Suchen..."
          selectedLabel="Ausgewählt"
          size="md"
          :style="{ borderColor: $v.form.reisekuerzel.$error ? '#E5EAEE' : '#1BC5BD' }"
          style="border-radius: 8px"
        ></Multiselect>
      </b-form-group>
      <b-form-group v-if="!isSerie" class="w-100" label="Abreisedatum" label-for="date-picker">
        <DatePicker
          :lang="langConfig"
          v-model="$v.form.abreisedatum.$model"
          :disabledDate="disabledBeforeToday"
          placeholder="Wähle ein Datum..."
          format="DD.MM.YYYY"
          class="w-100"
        ></DatePicker>
        <div
          v-for="(extraAbreisedatum, i) in extraAbreisedatums"
          :key="'extraAbreisedatum-' + i"
          class="mt-2 d-flex"
          style="gap: 12px"
        >
          <DatePicker
            :lang="langConfig"
            v-model="extraAbreisedatums[i].value"
            :disabledDate="disabledBeforeToday"
            placeholder="Wähle ein Datum..."
            format="DD.MM.YYYY"
            class="w-100"
          ></DatePicker>
          <i
            class="fas fa-times text-danger align-self-center"
            @click="removeAbreisedatum(extraAbreisedatum.id)"
            style="cursor: pointer"
          ></i>
        </div>
        <div class="bg-primary plus-sign mt-4" @click="addAbreisedatum">+</div>
      </b-form-group>
      <div v-if="isSerie" class="mt-4">
        <div class="d-flex" style="gap: 24px; align-items: center">
          <span style="width: 80px">Beginn</span>
          <DatePicker
            :lang="langConfig"
            v-model="$v.form.beginnSerie.$model"
            :disabledDate="disabledBeforeToday"
            placeholder="Wähle ein Startdatum..."
            format="DD.MM.YYYY"
            class="w-100"
          ></DatePicker>
        </div>
        <div class="d-flex mt-4" style="gap: 24px; align-items: center">
          <span style="width: 80px">Ende</span>
          <DatePicker
            :lang="langConfig"
            v-model="$v.form.endeSerie.$model"
            :disabledDate="disabledBeforeToday"
            :default-value="form.beginnSerie ?? new Date()"
            placeholder="Wähle ein Enddatum..."
            format="DD.MM.YYYY"
            class="w-100"
          ></DatePicker>
        </div>

        <b-form-group class="mt-6">
          <div class="mb-2">Serienmuster:</div>
          <div class="p-4" style="background-color: #e0e0e0; border-radius: 12px">
            <span class="d-flex align-items-center">
              Jede/Alle
              <b-form-input
                v-model="everyXWeek"
                style="width: 40px; height: 24px"
                class="ml-2 mr-2"
              ></b-form-input>
              Woche(n) am:
            </span>
            <b-form-checkbox-group
              v-model="selectedDays"
              :options="dayOptions"
              value-field="item"
              text-field="name"
              class="mt-2"
              style="display: flex; flex-wrap: wrap; gap: 12px"
            ></b-form-checkbox-group>
          </div>
        </b-form-group>
      </div>
      <div
        v-if="isSerie && formatDatesInInterval.length > 0"
        style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 6px"
      >
        <li v-for="date in formatDatesInInterval" :key="date">
          {{ date }}
          <i
            class="fas fa-times text-danger align-self-center ml-1 pt-1"
            @click="removeDateFromInterval(date)"
            style="cursor: pointer"
          ></i>
        </li>
      </div>
      <b-form-checkbox class="mt-2" v-model="isSerie" button button-variant="primary">
        {{ isSerie ? 'Termin anlegen' : 'Serie anlegen' }}
      </b-form-checkbox>
    </b-overlay>

    <template #modal-footer="{ hide }" class="pl-1">
      <b-button
        class="mr-4"
        :disabled="disableSichernButton"
        :variant="disableSichernButton ? 'secondary' : 'success'"
        @click="reiseterminAnlegen(hide)"
      >
        Erstellen
      </b-button>
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { validationMixin } from 'vuelidate';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';
import { checkDateInFuture, exactlySixChars, noDigits } from '@/core/common/helpers/utils.js';
import apiService from '@/core/common/services/api.service';
import { mapState } from 'vuex';
import { addDays, getDay, isAfter, getWeek, format, parse } from 'date-fns';
import { langConfig } from '@/core/common/helpers/utils.js';

export default {
  mixins: [validationMixin],
  name: 'ReiseterminAnlegenModal',
  components: { Multiselect, DatePicker },
  watch: {
    beginnSerie() {
      this.datesInInterval = this.getDatesInInterval;
    },
    endeSerie() {
      this.datesInInterval = this.getDatesInInterval;
    },
    everyXWeek() {
      this.datesInInterval = this.getDatesInInterval;
    },
    selectedDays() {
      this.datesInInterval = this.getDatesInInterval;
    },
  },
  data() {
    return {
      langConfig,
      isLoading: false,
      isSerie: false,
      everyXWeek: 1,
      selectedDays: [],
      dayOptions: [
        { item: 1, name: 'Montag' },
        { item: 2, name: 'Dienstag' },
        { item: 3, name: 'Mittwoch' },
        { item: 4, name: 'Donnerstag' },
        { item: 5, name: 'Freitag' },
        { item: 6, name: 'Samstag' },
        { item: 0, name: 'Sonntag' },
      ],
      form: {
        abreisedatum: null,
        reisekuerzel: null,
        beginnSerie: null,
        endeSerie: null,
      },
      datesInInterval: [],
      extraAbreisedatums: [],
    };
  },
  validations: {
    form: {
      abreisedatum: {
        required,
        checkDateInFuture,
      },
      reisekuerzel: {
        required,
        exactlySixChars,
        noDigits,
      },
      beginnSerie: {
        ifChecked(v) {
          return this.isSerie && required(v);
        },
      },
      endeSerie: {
        ifChecked(v) {
          return this.isSerie && required(v);
        },
      },
    },
  },
  computed: {
    ...mapState({
      reisekuerzelOptions: state => state.reisen.reisenkuerzelFilterOptions,
    }),

    reisenOptions() {
      if (this.reisekuerzelOptions.length > 0) {
        return this.reisekuerzelOptions.map(reise => reise.reisekuerzel);
      }
      return [];
    },
    beginnSerie() {
      if (this.form.beginnSerie) {
        return this.form.beginnSerie;
      } else return null;
    },
    endeSerie() {
      if (this.form.endeSerie) {
        return this.form.endeSerie;
      } else return null;
    },
    minFaelligkeitsdatum() {
      return new Date();
    },
    disableSichernButton() {
      return (
        !this.form.reisekuerzel ||
        (!this.isSerie &&
          (this.form.abreisedatum == null || this.extraAbreisedatums.some(item => item.value == null))) ||
        (this.isSerie && this.formatDatesInInterval.length === 0)
      );
    },
    getDatesInInterval() {
      if (this.everyXWeek > 0 && this.beginnSerie && this.endeSerie && this.isSerie) {
        let result = [];
        // get all days that are selected in the period
        let iteratedDate = this.beginnSerie;
        while (!isAfter(iteratedDate, this.endeSerie)) {
          if (this.selectedDays.includes(getDay(iteratedDate))) {
            result.push(iteratedDate);
          }
          iteratedDate = addDays(iteratedDate, 1);
        }
        const weekOfSeriesStart = getWeek(this.beginnSerie, { weekStartsOn: 1 });

        // filter result by every x week
        result = result.filter(date => {
          const weekOfDate = getWeek(date, { weekStartsOn: 1 });
          const isWeekInPeriod = (weekOfDate - weekOfSeriesStart) % this.everyXWeek === 0;
          if (isWeekInPeriod) {
            return true;
          } else return false;
        });
        return result;
      } else return [];
    },
    formatDatesInInterval() {
      if (this.getDatesInInterval?.length > 0) {
        const dayFormatter = {
          Mo: 'Mo',
          Tu: 'Di',
          We: 'Mi',
          Th: 'Do',
          Fr: 'Fr',
          Sa: 'Sa',
          Su: 'So',
        };
        return this.datesInInterval.map(date => {
          const formattedDate = format(date, 'EEEEEE., dd.MM.yyyy');
          const germanWeekDays = dayFormatter[formattedDate.substring(0, 2)] + formattedDate.substring(2);
          return germanWeekDays;
        });
      } else return [];
    },
  },
  methods: {
    addAbreisedatum() {
      this.extraAbreisedatums.push({
        id: crypto.randomUUID(),
        value: null,
      });
    },
    removeAbreisedatum(id) {
      this.extraAbreisedatums = this.extraAbreisedatums.filter(item => item.id != id);
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    removeDateFromInterval(dateToDelete) {
      const formattedDateToDelete = new Date(parse(dateToDelete.substring(5), 'dd.MM.yyyy', new Date()));
      this.datesInInterval = this.datesInInterval.filter(
        date => date.getTime() != formattedDateToDelete.getTime()
      );
    },
    async reiseterminAnlegen(hide) {
      this.isLoading = true;
      const params = {
        reisekuerzel: this.form.reisekuerzel,
      };
      if (this.isSerie) {
        if (this.datesInInterval.length === 0) {
          this.$bvToast.toast('Falsche Einstellungen der Serie, kein Datum gefunden.', {
            title: 'Error',
            variant: 'danger',
          });

          return;
        }
        params.abreisedatum = this.datesInInterval.map(date => format(date, 'yyyy-MM-dd'));
      } else {
        params.abreisedatum = [format(this.form.abreisedatum, 'yyyy-MM-dd')];

        if (this.extraAbreisedatums.length > 0) {
          params.abreisedatum = [
            ...params.abreisedatum,
            ...this.extraAbreisedatums.map(item => format(item.value, 'yyyy-MM-dd')),
          ];
        }
      }
      apiService
        .post('Reisetermin', params)
        .then(() => {
          this.toast('Erfolgreich erstellt.');
          this.isLoading = false;
          hide();
          this.$emit('reiseterminAnlegen');
        })
        .catch(() => {
          this.isLoading = false;
          hide();
        });
    },
    onShow() {
      this.$v.form.$touch();
    },
    onHide() {
      this.form = {
        abreisedatum: null,
        reisekuerzel: null,
        beginnSerie: null,
        endeSerie: null,
      };
      this.extraAbreisedatums = [];
      this.isSerie = false;
      this.everyXWeek = 1;
      this.selectedDays = [];
    },
  },
};
</script>

<style scoped>
.centerModal {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.multiselect__tags) {
  min-height: 36px;
  border-radius: 8px;
  background-color: var(--background-color);
}
:deep(.multiselect__single) {
  font-size: var(--font-size);
  background-color: var(--background-color);
  opacity: 1;
}
:deep(.multiselect__select) {
  height: 36px;
  border-radius: 8px;
  margin-top: 2px;
}
.multiselect {
  border: 1px solid var(--border-color);
  background-color: var(--background-color) !important;
  min-height: 36px;
}
:deep(.mx-input-wrapper > input) {
  font-size: 1rem;
  border: 1px solid #e8e8e8;
  height: 36px;
}
:deep(input::placeholder) {
  color: #adadad;
}
.plus-sign {
  height: 24px;
  width: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
