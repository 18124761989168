<template>
  <div class="d-flex align-items-start card-spacer-x py-2">
    <div class="d-flex flex-column flex-grow-1 flex-wrap mr-2 mt-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center mb-2">
          <span class="font-size-lg font-weight-bolder text-dark-75 mr-2">
            {{ $store.getters.getUsernameById(comment.creator) }}
          </span>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted mr-2">
              {{ lastChangeDate }}
            </div>
            <div v-if="!isToolbarHidden" class="d-flex align-items-center" data-inbox="toolbar">
              <span
                class="btn btn-clean btn-xs btn-icon"
                @click.stop="$emit('comment-mark', comment)"
                :class="{ disabled: isLoading }"
                :disabled="isLoading"
                data-placement="top"
                :title="$t('COMMON.MARKIEREN')"
              >
                <i class="flaticon-star icon-1x" :class="{ 'text-warning': comment.isMarked }"></i>
                <span
                  title="Anzahl markierter Antworten"
                  class="amount-marked text-warning"
                  v-if="amountMarkedAnwsers"
                >
                  {{ amountMarkedAnwsers }}
                </span>
              </span>
              <span
                v-if="isAdmin || ownUserId === comment.creator"
                class="btn btn-clean btn-xs btn-icon"
                @click.stop="commentDelete(comment)"
                :class="{ disabled: isLoading }"
                :disabled="isLoading"
                data-placement="top"
                title="Löschen"
              >
                <i class="flaticon2-rubbish-bin icon-1x"></i>
              </span>
              <span
                v-if="isAdmin || ownUserId === comment.creator"
                class="btn btn-clean btn-xs btn-icon"
                @click.stop="$emit('comment-edit', comment)"
                :class="{ disabled: isLoading }"
                data-placement="top"
                title="Editieren"
              >
                <i class="flaticon-edit icon-1x"></i>
              </span>
              <span
                class="btn btn-clean btn-xs btn-icon"
                @click.stop="copyComment(comment)"
                :class="{ disabled: isLoading }"
                data-placement="top"
                title="Kopieren"
              >
                <i :class="copied === true ? 'fas fa-check icon-1x' : 'far fa-copy icon-1x'"></i>
              </span>
              <span
                v-if="!isAnswer"
                class="btn btn-clean btn-xs btn-icon"
                @click.stop="
                  $emit('comment-reply', comment);
                  isAnswersShowing = true;
                "
                :class="{ disabled: isLoading }"
                data-placement="top"
                title="Antworten"
              >
                <i class="flaticon-reply icon-1x"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div
          class="font-weight-bold comment"
          :class="{ expanded: isExpanded }"
          ref="comment-content"
          v-html="comment.text"
        ></div>
        <template v-if="isAnswersShowing">
          <ReiseterminComment
            v-for="answer in comment.answers"
            :key="answer.id"
            :comment="answer"
            :isAnswer="true"
            :isAdmin="isAdmin"
            :isLoading="isLoading"
            :ownUserId="ownUserId"
            @comment-delete="$emit('comment-delete', answer)"
            @comment-edit="$emit('comment-edit', answer)"
            @comment-mark="$emit('comment-mark', answer)"
          ></ReiseterminComment>
        </template>

        <div
          v-if="isExpandable || (comment.answers && comment.answers.length > 0)"
          class="separator separator-solid"
        ></div>
        <div class="d-flex font-size-sm">
          <span
            v-if="isExpandable"
            @click.stop="isExpanded = !isExpanded"
            class="p-1 mt-1 mr-1 cursor-pointer text-hover-primary rounded"
          >
            {{ isExpanded ? 'Weniger anzeigen' : 'Mehr anzeigen' }}
          </span>
          <span
            class="p-1 mt-1 cursor-pointer text-hover-primary rounded"
            @click.stop="isAnswersShowing = !isAnswersShowing"
            v-if="comment.answers && comment.answers.length > 0"
          >
            {{
              isAnswersShowing
                ? 'Antworten verstecken'
                : '+' + comment.answers.length + ' Antwort' + (comment.answers.length === 1 ? '' : 'en')
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.comment {
  max-height: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;

  &.expanded {
    max-height: fit-content;
  }
}

.amount-marked {
  font-size: 1rem;
  margin-left: 0.2rem;
}
</style>

<script>
import ReiseterminComment from '@/components/produkte/reisetermine/reisetermin-comment.vue';
import { format, sub } from 'date-fns';
export default {
  components: { ReiseterminComment },
  name: 'ReiseterminComment',
  props: {
    comment: { type: Object },
    isAnswer: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
    isToolbarHidden: { type: Boolean, default: false },
    isInWorkflowModal: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    ownUserId: { type: String },
  },
  data() {
    return {
      isExpanded: false,
      isExpandable: false,
      isAnswersShowing: false,
      copied: false,
    };
  },
  mounted() {
    const commentContentRef = this.$refs['comment-content'];
    this.isExpandable = commentContentRef.offsetHeight < commentContentRef.scrollHeight;
  },
  computed: {
    amountMarkedAnwsers() {
      return this.comment?.answers?.filter(answer => answer.isMarked)?.length || 0;
    },
    lastChangeDate() {
      if (this.comment) {
        return this.formatDate(this.comment.lastChangeDatetime);
      }
      return '';
    },
  },
  methods: {
    async commentDelete(comment) {
      const confirmed = await this.$bvModal.msgBoxConfirm('Willst du den Kommentar wirklich löschen?', {
        centered: true,
        okTitle: 'OK',
        cancelTitle: 'Abbrechen',
      });
      if (confirmed) {
        this.$emit('comment-delete', comment);
      }
    },
    copyComment(comment) {
      const type = 'text/html';
      const blob = new Blob([comment.text], { type });
      const { ClipboardItem } = window;
      const data = [new ClipboardItem({ [type]: blob })];
      navigator.clipboard
        .write(data)
        .then(() => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 1500);
        })
        .catch(err => {
          console.log(err);
        });
    },
    formatDate(date) {
      return format(new Date(date), 'dd.MM.yyyy HH:mm');
    },
  },
};
</script>
